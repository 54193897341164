<template>
  <!-- modal Edit-->
  <validation-observer ref="passwordForm" #default="{ handleSubmit }">
    <b-modal
      id="modal-reset-user-password"
      hide-footer
      ok-title="Edit"
      cancel-title="Close"
      cancel-variant="outline-secondary"
      centered
      :title="getTitle()"
      @ok="handleEdit"
      :ok-disabled="false"
    >
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form @submit.prevent="handleSubmit(handleEdit)" ref="resetPasswordForm">
          <div class="px-2">
            <validation-provider
              #default="{ errors }"
              name="New Password"
              vid="old_password"
              rules="required"
            >
              <b-form-group class="required" label="New Password:">
                <b-form-input
                  id="password"
                  type="password"
                  placeholder="New password"
                  v-model="userInfo.password"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="{ errors }"
              name="Confirm New Password"
              vid="password_confirmation"
              rules="required"
            >
              <b-form-group class="required" label="Confirm New Password:">
                <b-form-input
                  type="password"
                  v-model="userInfo.password_confirmation"
                  placeholder="Confirm New Password"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-group>
            </validation-provider>
          </div>
          <div class="d-flex flex-wrap m-2 justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-sm-2 mobile-w100"
              type="submit"
            >
              <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hideModal"
              class="mt-1 mt-sm-0 mobile-w100"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </b-modal>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import { ref, onMounted } from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  BModal,
  BButton,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      loading: false,
    };
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTitle() {
      return "Edit " + this.userInfo.name + " password";
    },
    resetForm() {
      this.userInfo = { passowrd: "", password_confirmation: "" };
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-reset-user-password", "#btnShow");
    },
    handleEdit() {
      this.loading = true;
      axiosIns
        .post("/auth/setPassword", this.userInfo)
        .then((response) => {
          this.loading = false;
          this.$bvModal.hide("modal-reset-user-password");
          this.$swal({
            text: response.data.message,
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        })
        .catch((error) => {
          this.loading = false;
          const message = error.response.data.message;
          var showMessage = "";
          var showMessage = message;
          if (typeof showMessage === "object") {
            showMessage = "";
            for (var key in message) {
              if (message.hasOwnProperty(key)) {
                showMessage += message[key] + "\n";
              }
            }
          }
          this.$swal({
            text: showMessage,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            showClass: {
              popup: "animate__animated animate__bounceIn",
            },
            buttonsStyling: false,
          });
        });
    },
  },
};
</script>

<style></style>
